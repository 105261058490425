/**
 * Create Cleave.js instances from data attribute
 * @doc https: //nosir.github.io/cleave.js/
 */

import Cleave from 'cleave.js';
import 'cleave.js/dist/addons/cleave-phone.nz';
import { CleaveEventType, ICleaveEvent } from 'core/events/cleave';
import creditCardUtil from './creditcard';

const cleaveRules: Record<string, any> = {
    'expiration-date': {
        date: true,
        datePattern: ['m', 'y']
    },
    date: {
        date: true,
        datePattern: ['d', 'm', 'Y']
    },
    flybuys: {
        delimiter: ' ',
        blocks: [4, 4, 4, 4]
    },
    'credit-card': {
        creditCard: true,
        onBlur() {
            const value = (String($(this).val()) || '').replace(/\s/g, '');
            const type = creditCardUtil.getType(value);
            const $inputGroup = $(this).closest('.input-group');

            $inputGroup[0].dataset.creditCardType = type;
            $inputGroup.find('.credit-card-icon').hide();
            $inputGroup.find(`.credit-card-icon[data-credit-card-type="${type}"]`).show();

            $(this).trigger('cleave:credit-card-type', { value, type });
        }
    },
    phone: {
        phone: true,
        phoneRegionCode: 'NZ'
    }
};

/**
 * Apply input mask based on data-mask attribute of element.
 *
 * @param element - element to apply InputMask
 */
export function createInputMask(element: HTMLElement) {
    const mask = element.dataset.mask;
    if (!mask) return;

    const rule = cleaveRules[mask];
    const cleave = new Cleave(element, {
        ...rule,
        onValueChanged: (event: any) => {
            element.dispatchEvent(new CustomEvent<ICleaveEvent>(CleaveEventType.CHANGE, {
                bubbles: true,
                detail: {
                    value: event.target.value,
                    rawValue: event.target.rawValue
                }
            }))
        }
    });
    $(element).data('cleave', cleave);

    if (element.dataset.mask === 'credit-card') $(element).on('blur', rule.onBlur);
}
